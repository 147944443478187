import React from 'react'
import {graphql} from 'gatsby'
import moment from "moment"

import Layout from '../components/Layout/Layout'
import {Container, Row, Col} from 'react-bootstrap'
import SEO from "../components/seo"
import BlogSidebar from '../components/BlogSidebar/BlogSidebar'
import SendgridAddContact from "../components/SendgridAddContact/SendgridAddContact"

const BlogPost = (props) => {
  const post = props.data.contentfulBlogPost

  return (
    <Layout>
      <SEO title={post.title} description={post.description.description} />
      <Container style={{ paddingTop: "70px", maxWidth: "950px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={8} style={{ paddingBottom: "50px", maxWidth: "650px" }}>
            <div>
              <h3>{post.title}</h3>
              <h6>{post.description.description}</h6>
              <p style={{ color: "darkgrey" }}>
                {post.body.childMarkdownRemark.timeToRead} minute read ∙{" "}
                {moment(post.publishDate, "YYYY-MM-DD").format("MMM Do, YYYY")}
              </p>
            </div>
            <hr
              style={{ color: "lightgrey", width: "80%", margin: "30px auto" }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
            <div style={{ marginTop: "50px" }} />
            <SendgridAddContact listName="newsletter" />
          </Col>
          <Col lg={4} style={{ maxWidth: "650px" }}>
            <BlogSidebar currentPost={post.slug} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogPost;

export const query = graphql`
         query PostQuery($slug: String!) {
           contentfulBlogPost(slug: { eq: $slug }) {
             body {
               childMarkdownRemark {
                 html
                 timeToRead
               }
             }
             title
             publishDate
             heroImage {
               fluid {
                 ...GatsbyContentfulFluid
               }
             }
             description {
               description
             }
             slug
           }
         }
       `

