import React from "react"
import moment from "moment"
import { graphql, Link, useStaticQuery } from "gatsby"

import { clickEvent } from "../../utils/events"

import styles from "./BlogSidebar.module.css"

const BlogSidebar = ({ currentPost }) => {
  const data = useStaticQuery(graphql`
    query tempQuery {
      allContentfulBlogPost(
        sort: { fields: publishDate, order: DESC }
        limit: 6
      ) {
        edges {
          node {
            slug
            publishDate
            title
          }
        }
      }
    }
  `)

  let recentPosts = []
  data.allContentfulBlogPost.edges.forEach(({ node }) => {
    if (node.slug !== currentPost && recentPosts.length <= 5)
      recentPosts.push(node)
  })

  return (
    <div className={styles.container}>
      <h6 style={{ color: "#555" }}>Recent Posts</h6>
      {recentPosts.map((node, i) => (
        <div className={styles.historicalPost} key={i}>
          <div className={styles.date}>
            {moment(node.publishDate, "YYYY-MM-DD").format("MMM, DD YYYY")}
          </div>
          <Link
            id={i}
            to={`/blog/${node.slug}`}
            className={styles.priorPost}
            onClick={() =>
              clickEvent("Blog Sidebar", "View Blog Post", node.slug)
            }
          >
            {node.title}
          </Link>
        </div>
      ))}
      <div className={styles.more}>
        <Link
          to="/blog"
          onClick={() => clickEvent("Blog Sidebar", "View All Blogs")}
        >
          See all >
        </Link>
      </div>
    </div>
  )
}

export default BlogSidebar
